import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";

// Firestore query to get the visualization of a planet's species.
export const getTaskVisualization = async (problemName) => {
    // Get the reference of the document with the given problem name in the "problems" collection.
    const docRef = doc(firestore, "problems", problemName); 
    const docSnap = await getDoc(docRef); 
    if (docSnap.exists()) {
        // Get the problem's topic, then find that topic in the "topics" collection and retrieve a snapshot of it.
        const planetToQuery = docSnap.data().topicID;
        const planetRef = doc(firestore, "topics", planetToQuery);
        const planetSnap = await getDoc(planetRef);
        if(planetSnap.exists()){
            // If the snapshot exists, get the image filename, then return it as a result.
            const imageName = planetSnap.data().visualization.image_filename;
            return imageName;
        }
        return docSnap.data();
    } else {
        return { content: null };
    }
}
