import { FormControl, styled } from '@mui/material'

const LoginForm = styled(FormControl)(({ theme }) => ({
    [
        theme.breakpoints.down('sm')
    ]:
    {
        width: '100%',
    },
    width: '70%',
    marginTop: '1.5rem'
}));

export default LoginForm;