import { Button, styled } from '@mui/material'

const TaskOverviewButton = styled(Button)(({ theme }) => ({
  [
    theme.breakpoints.down('md')
  ]:
  {
    width: '100% !important',
  },
  ":hover": {
    backgroundColor: 'rgba(53, 103, 118, 1)'
  },
  border: '3px solid rgba(255,255,255,0.5)',
  borderRadius: '20px',
  fontSize: '2rem',
  margin: '0px auto 0px auto',
  padding: "30px",
  width: '80% !important',
  backgroundColor: 'rgba(53, 103, 118, 0.7)',
  color: 'white',
  textTransform: 'none'
})) as unknown as typeof Button;

export default TaskOverviewButton;
