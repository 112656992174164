import React from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
  Icon,
} from "@mui/material";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import PlanetsIcon from "../images/planets_logo.png";

// Array of dropdown menu items.
const settings = [
  { text: "My profile", icon: "settings", action: "myProfileAction" },
  { text: "Sign out", icon: "logout", action: "logoutAction" },
];

// Generate the greeting according to the current local time.
function getGreeting() {
  let currentDate = new Date();
  let currentHour = currentDate.getHours();
  let greet;
  if (currentHour < 12) greet = "morning";
  else if (currentHour >= 12 && currentHour < 18) greet = "afternoon";
  else if (currentHour >= 18 && currentHour <= 24) greet = "evening";
  return greet;
}

// Main navigation component to be rendered.
function Navigation() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const navigate = useNavigate();
  const timeOfDay = getGreeting();

  // Log user out, call signOut() of Firebase and navigate user to the path provided as a parameter.
  const logout = (navigateTo) => {
    setDisabled(true);
    signOut(auth)
      .then(() => {
        navigate(navigateTo);
      })
      .catch((error) => {
        console.error(error);
        setDisabled(false);
      });
  };

  // Show the dropdown menu
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (event) => {
    // Handle dropdown menu item clicks
    settings.forEach((s) => {
      if (s.text === event.currentTarget.getAttribute("id")) {
        if (s.action === "logoutAction") {
          logout("/login");
        } else if (s.action === "myProfileAction") {
          navigate("/profile");
        }
      } else {
        // Hide the dropdown menu
        setAnchorElUser(null);
      }
    });
  };

  return (
    <AppBar
      position="static"
      id="mainNav"
      className="mainMenuComponent"
      sx={{
        py: 1,
        borderBottom: "3px solid rgba(255,255,255,0.5)",
        backgroundColor: "rgba(53, 103, 118, 0.5)",
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          {/*DESKTOP VIEW START*/}
          <Icon
            component={"img"}
            alt=" "
            src={PlanetsIcon}
            sx={{
              display: { xs: "flex", fontSize: "4.5rem", marginRight: "1rem" },
            }}
          />
          <Typography
            variant="h4"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Planets
          </Typography>
          {/*DESKTOP VIEW END*/}

          {/*MOBILE VIEW START*/}
          <Typography
            variant="h4"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              color: "inherit",
              textDecoration: "none",
            }}
          >
            Planets
          </Typography>
          {/*MOBILE VIEW END*/}

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    color: "white",
                    textDecoration: "none",
                  }}
                >
                  {/* Display the welcome message based on the time of day and the user's display name. If there is no display name available, display John Doe. (this happens with email link registrations) */}
                  {auth.currentUser.displayName
                    ? `Good ${timeOfDay}, ${
                        auth.currentUser.displayName.split(" ")[0]
                      }!`
                    : `Good ${timeOfDay}, John Doe!`}
                </Typography>
                {/* Display the user's own profile image if it's available, otherwise the basic Avatar MUI component placeholder image will be displayed */}
                <Avatar
                  src={auth.currentUser ? auth.currentUser.photoURL : ""}
                  sx={{ width: 50, height: 50 }}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* Render each dropdown menu items that are items of the settings array. */}
              {settings.map((item) => (
                <MenuItem
                  key={item.text}
                  id={item.text}
                  onClick={handleCloseUserMenu}
                >
                  <Icon fontSize="small" sx={{ mr: 1 }}>
                    {item.icon}
                  </Icon>
                  <Typography textAlign="center">{item.text}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navigation;
