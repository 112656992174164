import { Box, styled } from '@mui/material'

const LoginBox = styled(Box)(({ theme }) => ({
    [
        theme.breakpoints.up('sm')
      ]:
      {
        margin: '10vw'
      },
    padding: '30px',
    backgroundColor: 'rgba(53, 103, 118, 0.5)',
    color: 'white',
    border: '3px solid rgba(255,255,255,0.5)',
    borderRadius: '20px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: 'center',
    height: '550px',
}));

export default LoginBox;