import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { auth } from "./config/firebase";
import Center from "./components/utils/Center";
import AnimatedRoutes from "./components/utils/AnimatedRoutes";

function App() {
  const [loading, setLoading] = useState(true);

  /* Log authentication status in the console. */

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.info("User detected.");
      } else {
        console.info("No user detected.");
      }
      setLoading(false);
    });
  }, []);

  // Display a circular loading animation when the page is loading.
  if (loading)
    return (
      <Center>
        <CircularProgress />
      </Center>
    );

  return (
    <div>
      <BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
    </div>
  );
}

export default App;
