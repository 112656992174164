import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";
import { getUserInfo } from "./users";

// Firestore query to retrieve the user's latest task. 
export const getUserLatest = async (userId) => {
    await getUserInfo(userId);
    // Get the document reference with the "userid" name from the "latest" collection. 
    const docRef = doc(firestore, "latest", userId);
    // Retrieve the snapshot with the given reference.
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        // Return the latest task's ID as a result.
        return docSnap.data().problem_id;
    } else {
        return { content: null };
    }
}
