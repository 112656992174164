import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";

// Firestore query to retrieve all tasks of a given topic.
export const getTaskList = async (topicId) => {
    // Get the document reference, where "topicId" is the ID of the document in the "topics" collection.
    const docRef = doc(firestore, "topics", topicId);
    // Retrieve the snapshot with the given reference.
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        // Return the topic's "problems" - tasks array as a result.
        return docSnap.data().problems;
    }
    else {
        return { content: null };
    }
}
