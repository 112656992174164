import { useState, useEffect } from "react";
import { auth } from "../config/firebase";
import { sendEmailVerification } from "firebase/auth";
import { Container, Grid, Avatar, Box, CircularProgress, Typography } from "@mui/material";
import ProfileInfo from "../components/utils/ProfileInfo";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { motion } from "framer-motion";
import SubmissionGrid from "../components/utils/SubmissionGrid";
import { getUserInfoWithSubmissions } from "../services/users";
import { getNumberOfChallenges } from "../services/totalchallenges"
import Center from "../components/utils/Center";

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsSizeOverrides {
    "1px": true;
  }
}

function MyProfile() {
  const user = auth.currentUser;
  function getUserJoined() {
    let joinDate = new Date(user.metadata.creationTime);
    let days = Math.ceil(
      (new Date().getTime() - joinDate.getTime()) / (1000 * 3600 * 24)
    );
    return (
      "You joined on " + joinDate.toLocaleDateString() + ` (${days} days ago)`
    );
  }

  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [challengesCount, setChallengesCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      getUserInfoWithSubmissions(user.uid).then((data) => {
        setUserInfo(data);
        setLoading(false);
      });
      getNumberOfChallenges().then((data) => {
        setChallengesCount(data);
      })
    };
    fetchData();
  }, [user]);

  // Display a circular loading animation while the user information arrives from Firebase via the query function.
  if (loading) {
      return (
        <Center>
          <Typography color="white" variant="h3">
              Please wait while we load your profile data...
            </Typography>
          <CircularProgress />
        </Center>
    );
  }

  function getUserCompletion() {
    return `You solved ${userInfo["data"].solved} out of ${challengesCount} total available challenges.`;
  }

  function getUserBalance() {
    return userInfo["data"].balance;
  }

  function getUserPoints() {
    return userInfo["data"].points;
  }

  // Send link to verify email address with upon click.
  function verifyUserEmail() {
    sendEmailVerification(auth.currentUser);
    return "Please verify your e-mail address via the link we sent you.";
  }

  return (
    <motion.div
      className="myprofileScreen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <Container
        maxWidth={false}
        sx={{
          margin: "50px auto 50px auto",
          width: {xs: "90vw", xl: "70vw"},
          height: "100%",
          border: "3px solid rgba(255,255,255,0.5)",
          backgroundColor: "rgba(53, 103, 118, 0.5)",
          color: "white",
        }}
      >
        <Grid
          className="myprofileGrid"
          container
          sx={{ margin: "20px auto 20px auto" }}
        >
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              imgProps={{ referrerPolicy: "no-referrer" }}
              src={user ? user.photoURL : ""}
              sx={{ width: 150, height: 150 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            lg={10}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <ProfileInfo variant="h3" sx={{ marginTop: "20px" }}>
              {/* If the user has no display name ( typically email link login with not a @gmail.com address ), display John Doe instead. */}
              {user.displayName ? user.displayName : "John Doe"}
            </ProfileInfo>
            <ProfileInfo>
              {user.email}
              {/* If the user's email address is verified, display a checkmark. Otherwise, display an empty circle.  */}
              {user.emailVerified ? (
                <CheckCircleIcon
                  role="img"
                  fontSize="1px"
                  titleAccess="Your e-mail address is verified."
                />
              ) : (
                <PanoramaFishEyeIcon
                  role="img"
                  fontSize="1px"
                  titleAccess="Your e-mail address is NOT verified yet."
                />
              )}
            </ProfileInfo>
            {/* If the user's email isn't verified yet, display a warning message just below their e-mail address while also sending them a verification link via email. */}
            <ProfileInfo sx={{ color: "yellow" }}>
              {!user.emailVerified ? verifyUserEmail() : ""}
            </ProfileInfo>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box sx={{ marginTop: "50px" }}>
              <ProfileInfo variant="h6">{getUserJoined()}</ProfileInfo>
              <ProfileInfo variant="h6" sx={{ marginTop: "0.5rem" }}>
                {getUserCompletion()}
              </ProfileInfo>
              <ProfileInfo variant="h5" sx={{ marginTop: "1.5rem" }}>
                {`Balance: $` + getUserBalance()}
              </ProfileInfo>
              <ProfileInfo variant="h5" sx={{ marginTop: "0.5rem" }}>
                {`Story points: ` + getUserPoints()}
              </ProfileInfo>
              <ProfileInfo variant="h5" sx={{ marginTop: "2.5rem" }}>
                Your submissions:
              </ProfileInfo>
              {/* Separate MUI DataGrid component that contains the user's submissions, with one submission's data per line. */}
              <SubmissionGrid userSubmissions={userInfo["submissions"]} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </motion.div>
  );
}

export default MyProfile;
