import * as React from "react";
import { Avatar, Box, CircularProgress, LinearProgress, Typography } from "@mui/material";
import {
  TransformWrapper,
  TransformComponent,
  useTransformEffect,
} from "react-zoom-pan-pinch";
import { motion } from "framer-motion";
import Xarrow, { Xwrapper } from "react-xarrows";
import { v4 as uuidv4 } from "uuid";
import { getTaskList } from "../services/tasklist";
import tasklayoutData from "../services/tasklayouts.json";
import { Link } from "react-router-dom";
import AstronautImg from "../images/astronaut.png";
import AstronautGreenImg from "../images/astronaut-green.png";
import FlagImg from "../images/flag.png";
import FlagGreenImg from "../images/flag-green.png";
import StarImg from "../images/star.png";
import StarGreenImg from "../images/star-green.png";
import RocketImg from "../images/rocket.png";
import RocketGreenImg from "../images/rocket-green.png";
import { auth, firestore } from "../config/firebase";
import { getPlanetList } from "../services/userplanets";
import Center from "../components/utils/Center";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LockIcon from "@mui/icons-material/Lock";
import DoneIcon from "@mui/icons-material/Done";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

// Load all SVGs from the public/planets_svg folder.
const reqSvgs = require.context("../../public/planets_svg", true, /\.svg$/);
const paths = reqSvgs.keys();

// Each TaskArrow corresponds to a set of arrows between a planet's tasks.
const TaskArrows = ({ layout, planetID, showLayout }) => {
  return (
    showLayout &&
    layout.map((_, index) =>
      index + 1 < layout.length ? (
        <Xarrow
          dashness={true}
          strokeWidth={0.5}
          lineColor={"black"}
          headColor={"black"}
          key={`layoutarrow/${index}`}
          start={`taskPosition-${planetID}-${index}`}
          end={`taskPosition-${planetID}-${index + 1}`}
          animateDrawing={false}
        />
      ) : null
    )
  );
};

const ProblemTitle = ({
  problem_id,
  opened,
  style,
}: {
  problem_id: string;
  opened: boolean;
  style?: React.CSSProperties;
}) => {
  const [problemTitle, setProblemTitle] = React.useState("?");
  React.useEffect(() => {
    const fetchProblemTitle = async () => {
      const docRef = doc(firestore, "problems", problem_id);
      const docSnap = await getDoc(docRef);
      setProblemTitle(docSnap.data().title);
    };
    if (opened) fetchProblemTitle();
  }, [problem_id, opened]);
  return <span style={style}>{problemTitle}</span>;
};

// Complete layout of each planet's tasks
const TasksLayout = ({ planetID }) => {
  const [showLayout, setShowLayout] = React.useState(true);
  const [taskList, setTaskList] = React.useState([]);
  const [layout, setLayout] = React.useState([]);
  const [problemProgress, setProblemProgress] = React.useState(
    new globalThis.Map()
  );

  useTransformEffect(({ state }) => {
    if (state.scale >= 6) {
      setShowLayout(true);
    } else {
      //setShowLayout(false)
    }
  });

  React.useEffect(() => {
    const fetchData = async () => {
      getTaskList(planetID).then((data) => {
        setTaskList(data);
      });
    };
    fetchData();
  }, [planetID]);

  React.useEffect(() => {
    if (taskList) {
      setTimeout(() => {
        setLayout(tasklayoutData[taskList.length]);
      }, 200);
    }
  }, [taskList]);

  React.useEffect(() => {
    const fetchProblemProgress = async () => {
      const docs = (
        await getDocs(
          collection(
            firestore,
            "users",
            auth.currentUser.uid,
            "problem_progress"
          )
        )
      ).docs;
      setProblemProgress(
        new globalThis.Map(docs.map((doc) => [doc.id, doc.data()]))
      );
    };
    fetchProblemProgress();
  }, []);

  // Select the appropriate image per task, based on the task's type.
  const taskTypeImage = (type, isSolved) => {
    switch (type) {
      case "ENTRY":
        return isSolved ? AstronautGreenImg : AstronautImg;
      case "COMPLETION":
        return isSolved ? RocketGreenImg : RocketImg;
      case "EXTRA":
        return isSolved ? StarGreenImg : StarImg;
      default:
        return isSolved ? FlagGreenImg : FlagImg;
    }
  };

  return (
    <Box
      sx={{
        width: "40%",
        height: "40%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 2,
        visibility: showLayout ? "visible" : "hidden",
      }}
    >
      {/* Map the complete layout, create an Avatar MUI component per each task with the corresponding task data. */}
      {layout.map((task, index) => {
        return (
          <div
            key={`taskPic-${index}`}
            id={`taskPosition-${planetID}-${index}`}
            style={{
              width: "10px",
              height: "15px",
              position: "absolute",
              top: `${layout[index].y}%`,
              left: `${layout[index].x}%`,
            }}
          >
            <Avatar
              component={Link}
              src={taskTypeImage(
                taskList[index].type,
                problemProgress.get(taskList[index].problem_id)?.state ===
                  "SOLVED"
              )}
              to={`/taskpreview/${taskList[index].problem_id}`}
              className="task-button"
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "1px",
              }}
            />
            <ProblemTitle
              problem_id={taskList[index].problem_id}
              opened={
                problemProgress.get(taskList[index].problem_id)?.state &&
                problemProgress.get(taskList[index].problem_id)?.state !==
                  "LOCKED"
              }
              style={{
                fontSize: "3px",
                fontWeight: "bold",
                position: "absolute",
                transform: "translateX(-50%)",
                width: "100px",
              }}
            />
          </div>
        );
      })}
      {/* Render arrows between the tasks of the planet */}
      <TaskArrows planetID={planetID} layout={layout} showLayout={showLayout} />
    </Box>
  );
};

// Determine the CSS class to set for each overlay, styling based on classes in index.css.
function planetClassFromState(pState) {
  let classValue = "discoveredPlanet";
  switch (pState) {
    case "LOCKED":
      classValue = "lockedPlanet";
      break;
    case "UNLOCKED":
      classValue = "unlockedPlanet";
      break;
    case "EXPLORED":
      classValue = "exploredPlanet";
      break;
    default:
      break;
  }
  return classValue;
}

// Return an icon to display on top of each planet, based on the class we assigned to it earlier.
function getOverlayIcon(pClass) {
  let returnElement = <></>;
  switch (pClass) {
    case "lockedPlanet":
      returnElement = (
        <LockIcon
          sx={{ position: "absolute", fontSize: "4rem", color: "red" }}
        />
      );
      break;
    case "unlockedPlanet":
      returnElement = (
        <QuestionMarkIcon
          sx={{ position: "absolute", fontSize: "4rem", color: "white" }}
        />
      );
      break;
    case "exploredPlanet":
      returnElement = (
        <DoneIcon
          sx={{ position: "absolute", fontSize: "4rem", color: "green" }}
        />
      );
      break;
    default:
      break;
  }
  return returnElement;
}

// Render an image per planet, conditional rendering, if planet is not an overlay planet, show the image itself, and if it's an overlay, place an overlay icon on top of it.
const PlanetImg = ({ id, planetImg, planetState, overlay = undefined }) => {
  const planetClass = planetClassFromState(planetState);
  return !overlay ? (
    <img
      className={
        overlay ? planetClass : planetState === "LOCKED" ? "lockedPlanet" : ""
      }
      width={150}
      height={150}
      id={`planet-${id}`}
      key={id}
      alt={`planet ${id}`}
      src={reqSvgs(paths[paths.indexOf(planetImg)])}
    />
  ) : (
    <>
      <img
        className={planetClass}
        width={150}
        height={150}
        id={`planet-${id}`}
        key={id}
        alt={`planet ${id}`}
        src={reqSvgs(paths[paths.indexOf(planetImg)])}
      />
      {getOverlayIcon(planetClass)}
    </>
  );
};

// Responsible for hiding the tasks layout on each planet, with the overlay being shown on top of the original planet.
const PlanetOverlay = ({
  idx,
  top,
  left,
  planetState,
  handlePlanetInteraction,
  planetImg,
  planetID,
}) => {
  const [showLayout, setShowLayout] = React.useState(true);
  // If state.scale is greater than or equal to 8, show the planet's tasks.
  useTransformEffect(({ state }) => {
    if (state.scale >= 8 && planetState !== "LOCKED") {
      setShowLayout(false);
    } else {
      setShowLayout(true);
    }
  });

  // Renders the overlaying planet if state.scale is less than 8, otherwise hides it, revealing the tasks and the planet layout.
  return (
    showLayout && (
      <Box
        onClick={handlePlanetInteraction}
        key={uuidv4()}
        sx={{
          width: "150px",
          height: "150px",
          position: "absolute",
          top,
          left,
          pointerEvents: "all",
          zIndex: 1,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PlanetImg
          id={planetID}
          planetImg={planetImg}
          planetState={planetState}
          overlay={true}
        />
      </Box>
    )
  );
};

// react-zoom-pan-pinch element, the TransformElement is the one we interact with, containing the page's main content, the map itself.
const TransformElement = (props) => {
  const [arrowLoad, setArrowLoad] = React.useState(false);
  const componentRef = React.useRef(null);
  const planetDB = props.userPlanets;

  React.useEffect(() => {
    setTimeout(() => {
      setArrowLoad(true);
    }, 750);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePlanetInteraction = (event) => {
    props.zoomToElement(event.target, 8);
  };

  const hash = window.location.hash;
  if (hash) {
    setTimeout(() => {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        props.zoomToElement(element, 8);
      }
    }, 1000);
  }

  return (
    <TransformComponent
      wrapperStyle={{
        width: "100vw",
        height: "80vh",
      }}
    >
      <Box
        sx={{
          width: "300vw",
          height: "300vh",
          border: '2px dashed rgba(255,255,255,0.5)',
          position: "relative",
        }}
        ref={componentRef}
      >
        {/* Iterate through planetDB, render each planet's image within a box, and then render the planet's progress bar underneath. */}
        {componentRef?.current &&
          planetDB.map((planetEntry, idx) => (
            <React.Fragment key={idx}>
              <Box
                onClick={handlePlanetInteraction}
                sx={{
                  width: "150px",
                  height: "150px",
                  position: "absolute",
                  top: `calc(${componentRef?.current?.getBoundingClientRect()?.height / 2 -
                    75 || 0
                    }px + ${planetEntry.visualization.position.y}px)`,
                  left: `calc(${componentRef?.current?.getBoundingClientRect()?.width / 2 -
                    75 || 0
                    }px + ${planetEntry.visualization.position.x}px)`,
                  pointerEvents: "all",
                  zIndex: 1,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PlanetImg
                  id={planetEntry.id}
                  planetImg={`./${planetEntry.visualization.image_filename}`}
                  planetState={planetEntry.progress.state}
                />
                <Box
                  sx={{
                    position: "absolute",
                    top: "105%",
                    width: "150px",
                    height: "25px",
                    color: "white",
                    backgroundColor: "transparent",
                    border: "2px white solid",
                    padding: "5px",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    color="inherit"
                    value={planetEntry.progress.completion_rate * 100}
                    sx={{
                      backgroundColor: "transparent",
                      height: "25px",
                    }}
                  />
                </Box>
                {planetEntry.progress.state === "LOCKED" || planetEntry.progress.state === "UNLOCKED" ? "" : (
                  <Box
                    sx={{
                      position: "absolute",
                      top: "-25%",
                      width: "400px",
                      color: "white",
                      backgroundColor: "transparent",
                      padding: "5px",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {planetEntry.name}
                    </Typography>
                  </Box>)}
                {/* If the planet in question has the state of LOCKED, do not render the layout. */}
                {planetEntry.progress.state === "LOCKED" ? (
                  ""
                ) : (
                  <Xwrapper>
                    <TasksLayout planetID={planetEntry.id} />
                  </Xwrapper>
                )}
              </Box>
              <PlanetOverlay
                idx={idx}
                left={`calc(${componentRef?.current?.getBoundingClientRect()?.width / 2 -
                  75 || 0
                  }px + ${planetEntry.visualization.position.x}px)`}
                top={`calc(${componentRef?.current?.getBoundingClientRect()?.height / 2 -
                  75 || 0
                  }px + ${planetEntry.visualization.position.y}px)`}
                planetID={planetEntry.id}
                planetState={planetEntry.progress.state}
                planetImg={`./${planetEntry.visualization.image_filename}`}
                handlePlanetInteraction={handlePlanetInteraction}
              />
            </React.Fragment>
          ))}
        {/* When arrowLoad is true, render the arrows between the planets by iterating through planetDB. */}
        {arrowLoad && (
          <Xwrapper>
            {planetDB.map((planetEntry) =>
              planetEntry.pre.map((prereq) => {
                // Check if the current prerequisite is displayed ( available for the user ), if yes, render the arrow.
                const planetAvailable = planetDB.some(p => p.id === prereq);
                return planetAvailable ? (<Xarrow
                  key={`arrowto/${planetEntry.id}`}
                  start={`planet-${prereq}`}
                  end={`planet-${planetEntry.id}`}
                  animateDrawing={false}
                  dashness={true}
                  strokeWidth={7}
                  lineColor={"orange"}
                  headColor={"orange"}
                  endAnchor="auto"
                />) : null;
              }
              ))}
          </Xwrapper>
        )}
      </Box>
    </TransformComponent>
  );
};

// Main parent component
function Map() {
  const [loading, setLoading] = React.useState(true);
  const [userPlanets, setUserPlanets] = React.useState([]);

  // Fetch data via Firestore query function.
  React.useEffect(() => {
    const fetchData = async () => {
      getPlanetList(auth.currentUser.uid).then((data) => {
        setUserPlanets(data);
        setLoading(false);
      });
    };
    fetchData();
  }, []);
  // While data is being fetched, show a circular loading animation.
  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <motion.div
      className="mapScreen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      style={{ pointerEvents: "auto", overflow: "hidden" }}
    >
      <TransformWrapper
        doubleClick={{ disabled: true }}
        initialScale={1}
        centerOnInit={true}
        minScale={1}
        maxScale={11}
      >
        {({ instance, zoomToElement, resetTransform, zoomIn, centerView }) => (
          <TransformElement
            zoomToElement={zoomToElement}
            resetTransform={resetTransform}
            zoomIn={zoomIn}
            instance={instance}
            centerView={centerView}
            userPlanets={userPlanets}
          />
        )}
      </TransformWrapper>
    </motion.div>
  );
}

export default Map;
