import { Typography, styled } from '@mui/material'

const ProfileInfo = styled(Typography)(({ theme }) => ({
    [
        theme.breakpoints.up('md')
    ]:
    {
        textAlign: 'left',
        marginLeft: '20px',
    },
    textAlign: 'center',
    lineHeight: 'normal'
}));

export default ProfileInfo;