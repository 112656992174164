import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";

// This function is called when a user visits a task preview page.
export const setUserLatest = async (userid, task) => {
    // Get the document's reference where doc ID matches "userid" in the "latest" collection.
    const userRef = doc(firestore, "latest", userid);
    // Async function to update the document.
    await updateDoc(userRef, {
        "problem_id": task
      });
      
}
