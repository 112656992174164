import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

// Columns of the table with 'field' as keys.
const columns = [
    {
        field: 'problem',
        headerName: 'Challenge',
        width: 200,
    },
    {
        field: 'type',
        headerName: 'Language',
        width: 120,
    },
    {
        field: 'result',
        headerName: 'Result',
        width: 200,
    },
    {
        field: 'submissionDate',
        headerName: 'Time of submission',
        width: 220,
    },
];

function SubmissionGrid(props) {
    const submissions = props.userSubmissions;

    // Process the received submissions data to fit the table's data requirements. 
    function getSubmissionRows() {
        let arrayOfRows = [];
        submissions.forEach((s, idx) => {
            arrayOfRows.push({id: idx + 1, problem: s.data.problem_id, type: s.data.language, result: s.data.verdict, submissionDate: s.data.timestamp.toDate().toLocaleString() });
          });
        return arrayOfRows;
    }

    let rows = getSubmissionRows();

    return (
        <Box sx={(theme) => ({
            [theme.breakpoints.up("xl")]: {
                width: '70%',
            },
            [theme.breakpoints.down("xl")]: {
                width: '100%',
            },
            height: '375px',
            marginTop: '30px'
        })}
        >
            {/* MUI DataGrid component with a custom style and additional parameters, like the sortModel and paginationModel. */}
            <DataGrid sx={{
                borderRadius: '20px',
                color: 'white',
                border: '3px solid rgba(255,255,255,0.5)',
                backgroundColor: 'rgba(53, 103, 118, 1)',
            }}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                    },
                    sorting: {
                        sortModel: [{ field: 'submissionDate', sort: 'desc' }],
                      },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
            />
        </Box >
    );
}

export default SubmissionGrid;
