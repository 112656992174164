import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";

// AuthChecker checks if a user is logged in: if not, redirects them to the login page.
const AuthChecker = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!auth.currentUser) {
    return null;
  }

  return <>{children}</>;
};

export default AuthChecker;
