import { collection, doc, getDoc, query, where, getDocs } from "firebase/firestore";
import { firestore, firebaseApp } from "../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions"


// Push the data of each doc into an array to be returned.
function processSubmissions(snapshot){
    let result = []
    snapshot.forEach((doc) => {
        result.push({data : doc.data()});
      });
    return result;
}

export const getUserInfo = async (uid) => {
    // Find the user's document within the "users" collection via the user ID.
    const docRef = doc(firestore, "users", uid);
    // Retrieve the document with the given reference.
    let docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
        const init = httpsCallable(getFunctions(firebaseApp, "europe-west1"), "planetsinituserdata");
        console.log("inituserdata", await init());
        docSnap = await getDoc(docRef);
    }
    return docSnap.data();
}

// Firestore query to get all user information for display on the profile page.
export const getUserInfoWithSubmissions = async (uid) => {
    let userInfo = {data : await getUserInfo(uid), submissions: []};
    // Get all submissions of the said user from the "submissions" collection.
    const submissionsCollection = collection(firestore, `submissions`);
    const sRef = query(submissionsCollection, where("user_id", "==", uid));
    const sSnap = await getDocs(sRef);
    if(sSnap){
        // Pass the snapshot of the subquery's result to be processed by the function that returns an array.
        userInfo.submissions = processSubmissions(sSnap);
        // Define userInfo as an object with the user's data and their submission, then return it to be displayed.
    }
    return userInfo;
}
