import React from "react";
import AuthContainer from "../components/auth/AuthContainer";
import Center from "../components/utils/Center";
import { motion } from "framer-motion";

// Login screen that renders the AuthContainer as it's main content.
const Login = (props) => {
  return (
    <motion.div
      className="mapScreen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <Center>
        <AuthContainer />
      </Center>
    </motion.div>
  );
};

export default Login;
