import Home from "../screens/Home";
import Login from "../screens/Login";
import Map from "../screens/Map";
import TaskPreview from "../screens/TaskPreview";
import TaskOverview from "../screens/TaskOverview";
import MyProfile from "../screens/MyProfile";
import PageNotFound from "../screens/PageNotFound";

const routes = [
  {
    path: "/",
    component: Home,
    name: "Planets - home",
    protected: true
  },
  {
    path: "/login",
    component: Login,
    name: "Planets - login",
    protected: false
  },
  {
    path: "/map",
    component: Map,
    name: "Planets - explore the map",
    protected: true
  },
  {
    path: "/taskpreview/:id",
    component: TaskPreview,
    name: "Planets - task preview",
    protected: true
  },
  {
    path: "/taskoverview/:id",
    component: TaskOverview,
    name: "Planets - task summary",
    protected: true
  },
  { 
    path: "/profile",
    component: MyProfile,
    name: "Planets - My profile",
    protected: true
  },
  { 
    path: "*",
    component: PageNotFound,
    name: "Planets - Page Not Found",
    protected: true
  }
];

export default routes;
