import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import MenuButton from "./utils/MenuButton";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../config/firebase";
import { getUserLatest } from "../services/getuserlatest";
import { getTeleportationTask } from "../services/teleportation";
import { CircularProgress, Typography } from "@mui/material";
import Center from "./utils/Center";

function MainMenu() {
  const navigate = useNavigate();
  const [userLatest, setUserLatest] = useState("");
  const [loading, setLoading] = useState(false);

  // Get which task the user last viewed via Firebase query function.
  useEffect(() => {
    const fetchData = async () => {
      getUserLatest(auth.currentUser.uid).then((data) => {
        setUserLatest(data);
      });

    };
    fetchData();
  });

const queryTeleportationChallenge = (() => {
    setLoading(true);
    getTeleportationTask().then((queryResult) => {
      setLoading(false);
      navigate(`/taskpreview/${queryResult.data}`);
    });
  });

  if (loading) {
    return (
      <Center>
        <Typography color="white" variant="h4">
            Teleportation in progress, please wait...
          </Typography>
        <CircularProgress />
      </Center>
    );
  }

  return (
    <Container maxWidth={false} id="mainMenu">
      <Stack
        id="mainMenuStack"
        spacing={3}
        className="mainMenuComponent"
        sx={{
          p: 2,
          alignItems: "center",
          marginTop: "-30px"
        }}
      >
        {/* Redirect the user to the preview of the task they last viewed. */}
        <MenuButton component={Link} to={`/taskpreview/${userLatest}`}>
          Continue where you left off
        </MenuButton>
        {/* Redirect the user to a random challenge that is selected by a server-side cloud function. */}
        <MenuButton component={Link} to="" onClick={() => queryTeleportationChallenge()}>
          Teleportation challenge +500 points
        </MenuButton>
        {/* Redirect the user to the map screen.*/}
        <MenuButton component={Link} to="/map">
          Explore map
        </MenuButton>
      </Stack>
    </Container>
  );
}

export default MainMenu;
