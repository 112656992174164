import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../config/firebase";

// Firestore query to get the task with the given ID and language. 
export const getProblemStatement = async (id, language) => {
    // Get the document reference.
    const docRef = doc(firestore, "problems", id, "translations", language);
    // Retrieve the snapshot with the given reference.
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        // Return the data if the snapshot exists.
        return docSnap.data();
    } else {
        return { content: null };
    }
}
