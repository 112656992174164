import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase.config";
import { initializeAuth, GoogleAuthProvider, GithubAuthProvider, browserSessionPersistence, browserPopupRedirectResolver } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Initialize Firebase with the config defined in ./firebase.config.ts
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = initializeAuth(firebaseApp, {
    persistence: browserSessionPersistence,
    popupRedirectResolver: browserPopupRedirectResolver,
 });

 // List of providers that are available to use for login.
export const Providers = { google: new GoogleAuthProvider(), github: new GithubAuthProvider() };
// Access Firestore via the firebaseApp recently initialized.
export const firestore = getFirestore(firebaseApp, "planets");
