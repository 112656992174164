import { useEffect } from "react";
import MainMenu from "../components/MainMenu";
import { motion } from "framer-motion";

// Menu parent component, renders the main menu.
const Home = (props) => {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <motion.div
      className="homeScreen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <MainMenu />
    </motion.div>
  );
};

export default Home;
