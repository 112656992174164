import React from 'react'
import routes from "../../config/routes";
import AuthChecker from "../auth/AuthChecker";
import Navigation from "../Navigation";
import { Link, Route, Routes } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { Button } from '@mui/material';

// Event listener for the 'backToMenu' button. If the user presses Backspace on any page (except the Login page), they will be navigated to the home screen.
window.addEventListener("keydown", (event) => {
    if (!(window.location.pathname === '/login') && event.code === "Backspace") {
        (document.querySelector('#backToMenu') as HTMLElement).click();
    }
});

function AnimatedRoutes() {
    return (
        <div>
            <AnimatePresence>
                <Routes>
                    {/* Map through the routes array to make sure each of them are accessible and have the components displayed below.*/}
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                route.protected ? (
                                    /* Check if the user is authenticated - if yes, display the navigation bar and the requested component, 
                                    then the button that reacts to Backspace for ease of use. */
                                    <AuthChecker>
                                        <Navigation />
                                        <Button id='backToMenu' component={Link} to="/"></Button>
                                        <route.component />
                                    </AuthChecker>
                                ) : (
                                    <route.component />
                                )
                            }
                        />
                    ))}
                </Routes>
            </AnimatePresence>
        </div>
    )
}

export default AnimatedRoutes
