import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import { motion } from "framer-motion";
import ufo from "../images/404notfound_ufo.png";
import alienbriefcase from "../images/404notfound_alienbriefcase.svg";
import alien from "../images/404notfound_alien.png";

//Array of 404 page images to be selected from.
const notFoundImages = [
  {
    source: alien,
    size: "200px",
  },
  {
    source: alienbriefcase,
    size: "300px",
  },
  {
    source: ufo,
    size: "200px",
  },
];

// Select a random image from the notFoundImages array to be displayed once the page renders.
const pickedImage =
  notFoundImages[Math.floor(Math.random() * notFoundImages.length)];

function PageNotFound() {
  const loadURL = window.location.href;
  const navigate = useNavigate();
  const [randomImage, setRandomImage] = useState({ source: "", size: "" });

  /* Set the image to be displayed via the randomImage state. 
    Once the page loads, redirect to the main page after 5 seconds.  */

  useEffect(() => {
    setRandomImage(pickedImage);
    setTimeout(() => {
      if (window.location.href === loadURL) {
        navigate("/");
      }
    }, 5000);
  }, [navigate, loadURL, setRandomImage]);

  return (
    <motion.div
      className="pageNotFoundScreen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      style={{ paddingBottom: "30px" }}
    >
      <Container
        sx={{
          height: "80vh",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* Box that contains the random image with it's given size and the titles. */}
        <Box sx={{}}>
          <img
            src={randomImage.source}
            alt=" "
            style={{ width: randomImage.size, height: randomImage.size }}
          ></img>
          <Typography color="white" variant="h3">
            Oops! Page not found.
          </Typography>
          <Typography color="white" variant="h5">
            You will be redirected to the main page in 5 seconds.
          </Typography>
        </Box>
      </Container>
    </motion.div>
  );
}

export default PageNotFound;
