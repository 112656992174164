import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Grid, Box, Button, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import Markdown from "../components/utils/Markdown";
import { getProblemStatement } from "../services/problems";
import PageNotFound from "./PageNotFound";
import Center from "../components/utils/Center";
import { getTaskVisualization } from "../services/visualization";
import { setUserLatest } from "../services/updateuserlatest";
import { auth } from "../config/firebase";

// Event listener that awaits the Spacebar to be pressed to skip to the next page.
window.addEventListener("keydown", (event) => {
  if (
    window.location.pathname.split("/")[1] === "taskpreview" &&
    (event.key === " " || event.code === "Space")
  ) {
    event.preventDefault();
    (document.querySelector("#pressToContinue") as HTMLElement).click();
  }
});

const reqSvgs = require.context("../../public/monsters_svg", true, /\.svg$/);
const paths = reqSvgs.keys();

function TaskPreview() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [statement, setStatement] = useState(null);
  const [artName, setArtName] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      getProblemStatement(id, "en").then((data) => {
        setStatement(data.statement);
      });
      getTaskVisualization(id).then((planetData) => {
        setArtName("./" + planetData);
        setLoading(false);
      });
    };
    fetchData();
    setUserLatest(auth.currentUser.uid, id);
  }, [id]);

  // Display a circular loading animation while the task's text arrives from Firestore.
  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  // If the task text is null after loading, render the 404 error page.
  if (!loading && !statement) {
    return <PageNotFound />;
  }

  return (
    <motion.div
      className="taskPreviewScreen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      style={{ paddingBottom: "30px" }}
    >
      <Container
        maxWidth={false}
        sx={{
          margin: "50px auto 50px auto",
          width: { xs: "90vw", xl: "70vw" },
          height: "100%",
          border: "3px solid rgba(255,255,255,0.5)",
          backgroundColor: "rgba(53, 103, 118, 0.5)",
          color: "white",
        }}
      >
        <Grid
          className="taskPreviewGrid"
          container
          sx={{ margin: "30px auto 30px auto" }}
        >
          {/* Create the grid item that contains an image of the planet's species. */}
          <Grid item xs={12} lg={3} textAlign="center">
            <img
              style={{
                width: "300px",
                height: "300px",
                margin: "auto",
              }}
              id={paths.indexOf(artName).toString()}
              alt={`planet creature`}
              src={reqSvgs(paths[paths.indexOf(artName)])}
            />
          </Grid>
          <Grid item sm={12} lg={9}>
            <Box fontSize={"1.3rem"} sx={{ margin: "20px 0px 0px 20px" }}>
              <div dangerouslySetInnerHTML={{ __html: statement }} />
            </Box>
          </Grid>
          {/* Button that takes you to the given task's overview page, with an animated title below in the span. */}
          <Grid item xs={12} sx={{ textAlign: "center", paddingTop: "5%" }}>
            <Button
              component={Link}
              id="pressToContinue"
              to={`/taskoverview/${id}`}
              sx={{
                color: "yellow",
                fontSize: "2rem",
              }}
            >
              <span className="swim-in">PRESS SPACE TO CONTINUE</span>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </motion.div>
  );
}

export default TaskPreview;
