import { Button, styled } from '@mui/material'

const MenuButton = styled(Button)(({ theme }) => ({
  ":hover": {
    backgroundColor: 'rgba(53, 103, 118, 0.9)',
  },
  [
    theme.breakpoints.up('xl')
  ]:
  {
    height: "12vh !important",
    width: "50vw !important",
    marginTop: "200px"
  },
  [
    theme.breakpoints.up('lg')
  ]:
  {
    height: "15vh",
    width: "60vw",
  },
  marginTop: '100px',
  display: 'flex', 
  flexDirection: "column", 
  justifyContent: "center",
  width: '100%',
  border: '3px solid rgba(255,255,255,0.5)',
  backgroundColor: 'rgba(53, 103, 118, 0.5)',
  padding: '2rem 0 2rem 0',
  fontSize: '2rem',
  textTransform: 'none',
  letterSpacing: '0.2rem',
  color: 'white',
  borderRadius: '20px',
  textAlign: "center"
})) as unknown as typeof Button;

export default MenuButton;
