import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { firestore, firebaseApp } from "../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions"
import { getUserInfo } from "./users";


// Firestore query to get all planets that are available for the user.
export const getPlanetList = async (userId) => {
    await getUserInfo(userId);
    // Get the reference of "topic_progress" where doc ID is "userid" in "users" collection.
    const docRef = collection(firestore, "users", userId, "topic_progress");
    // Retrieve the snapshot with the given reference.
    let docSnap = await getDocs(docRef);
    if (!docSnap) {
        const init = httpsCallable(getFunctions(firebaseApp, "europe-west1"), "planetsinituserdata");
        console.log("inituserdata", await init());
        docSnap = await getDocs(docRef);
    }
    // Iterate through the documents within the "topic_progress" collection, retrieving the topic name via the ID from the "topics" collection.
    let userPlanets = [];
    for (let i = 0; i < docSnap.docs.length; i++){
        let planetRef = doc(firestore, "topics", docSnap.docs[i].id);
        let planetSnap = await getDoc(planetRef);
        // Build an object with the data needed for representation, then add it to the userPlanets array to be returned.
        let currentPlanet = {"id" : docSnap.docs[i].id, "name" : planetSnap.data().name, "pre" : planetSnap.data().prerequisites, "dep" : planetSnap.data().dependents,"progress" : docSnap.docs[i].data(), "visualization" : planetSnap.data().visualization}
        userPlanets.push(currentPlanet);
    }
    return userPlanets;
}
