import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Grid, CircularProgress } from "@mui/material";
import Markdown from "../components/utils/Markdown";
import TaskOverviewButton from "../components/utils/TaskOverviewButton";
import { motion } from "framer-motion";
import { getProblemStatement } from "../services/problems";
import PageNotFound from "./PageNotFound";
import Center from "../components/utils/Center";
import ScrollContainer from "react-indiana-drag-scroll";

function TaskOverview() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [statement, setStatement] = useState(null);

  // Once the component mounts, query the task's data using it's ID retrieved from the URL, then set mdText to be the query's result.
  useEffect(() => {
    const fetchData = async () => {
      getProblemStatement(id, "en").then((data) => {
        setStatement(data.statement);
        setLoading(false);
      });
    };
    fetchData();
  }, [id]);

  // Display a circular loading animation while the task's text arrives from Firestore.
  if (loading) {
    return (
      <Center>
        <CircularProgress />
      </Center>
    );
  }
  // If the task text is null after loading, render the 404 error page.
  if (!loading && !statement) {
    return <PageNotFound />;
  }

  return (
    <motion.div
      className="taskOverviewScreen"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      style={{ paddingBottom: "30px" }}
    >
      <Container
        maxWidth={false}
        sx={{
          margin: "50px auto 50px auto !important",
          width: { xs: "90vw", xl: "70vw" },
          height: "100%",
          border: "3px solid rgba(255,255,255,0.5)",
          backgroundColor: "rgba(53, 103, 118, 0.5)",
          color: "white",
          textAlign: "justify",
        }}
      >
        <Grid container sx={{ margin: "5px auto 5px auto" }}>
          <Grid
            className="taskOverviewGrid"
            item
            sx={{
              padding: "0px 20px 0px 20px",
              margin: "30px auto 30px auto",
              scrollBehavior: "smooth",
            }}
          >
            <ScrollContainer
              hideScrollbars={false}
              vertical={true}
              className="scroll-container"
              style={{
                fontSize: "1.3rem",
                height: "auto",
                maxHeight: "700px",
                paddingRight: "30px",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: statement }} />
            </ScrollContainer>
          </Grid>
          <Grid
            item
            sx={{
              textAlign: "center",
              width: "70%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "30px auto 30px auto",
            }}
          >
            <TaskOverviewButton
              component={Link}
              to={`https://ide.algopro.hu/solve/planets/${id}`}
            >
              Start coding
            </TaskOverviewButton>
          </Grid>
        </Grid>
      </Container>
    </motion.div>
  );
}

export default TaskOverview;
