import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, Providers } from "../../config/firebase";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPopup,
} from "firebase/auth";
import {
  Typography,
  InputAdornment,
  Button,
  Divider,
  InputLabel,
  Input,
  Box,
  Container,
} from "@mui/material";
import {
  GithubLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import LoginBox from "../../components/utils/LoginBox";
import LoginForm from "../../components/utils/LoginForm";
import EmailIcon from "@mui/icons-material/Email";
import PlanetsLogo from "../../images/planets_logo.png";
import { getUserInfo } from "../../services/users";

const AuthContainer = (props) => {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [informMessage, setInformMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  // Regex to check if the user's email input is valid.
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  // Save the user's email in the userEmail state.
  const handleInputChange = (event) => {
    setUserEmail(event.target.value);
  };

  // This function is called when the user clicks on the "Log in with Google" button. Google's authentication window pops up for the user to log in, and upon a successful login, the user is redirected to the main page.
  const signInWithGoogle = () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.google)
      .then(() => {
        setDisabled(false);
        navigate("/");
        getUserInfo(auth.currentUser.uid); // check if user data exists, create if not
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          setErrorMessage(
            "You already have an account with this e-mail address! Please use that to log in."
          );
        } else {
          setErrorMessage(error.code + ": " + error.message);
          setDisabled(false);
        }
      });
  };

  // This function is called when the user clicks on the "Log in with GitHub" button. GitHub's authentication window pops up for the user to log in, and upon a successful login, the user is redirected to the main page.
  const signInWithGithub = () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.github)
      .then(() => {
        setDisabled(false);
        navigate("/");
        getUserInfo(auth.currentUser.uid); // check if user data exists, create if not
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          setErrorMessage(
            "You already have an account with this e-mail address! Please use that to log in."
          );
        } else {
          setErrorMessage(error.code + ": " + error.message);
          setDisabled(false);
        }
      });
  };

  // Firstly, check if the user's email address is valid. If it is, send the login link to their email address and display an info message on the bottom of the container.
  const signInWithEmail = () => {
    if (isEmail(userEmail)) {
      setDisabled(true);
      setInformMessage("Please click the link we just sent you to log in!");
      sendSignInLinkToEmail(auth, userEmail, {
        url: window.location.href,
        handleCodeInApp: true,
      }).then(() => {
        localStorage.setItem("email", userEmail);
      });
    } else {
      setErrorMessage("Please provide a valid e-mail address!");
    }
  };

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = localStorage.getItem("email");
      signInWithEmailLink(auth, email, window.location.href).then(() => {
        localStorage.removeItem("email");
        navigate("/");
        getUserInfo(auth.currentUser.uid); // check if user data exists, create if not
      });
    }
  }, [navigate]);

  return (
    <Container>
      <LoginBox>
        <Box
          component={"img"}
          src={PlanetsLogo}
          sx={{ height: "130px", width: "130px", mb: 0, mx: "auto" }}
        ></Box>
        <Typography variant="h4">Welcome to Planets!</Typography>
        <Typography variant="h6">Please sign in below.</Typography>
        <LoginForm variant="standard">
          <InputLabel sx={{ color: "white" }} htmlFor="email">
            Your email
          </InputLabel>
          <Input
            id="email"
            name="email"
            autoComplete="email"
            sx={{ mt: 1, color: "white" }}
            startAdornment={
              <InputAdornment position="start">
                <EmailIcon sx={{ color: "white" }} />
              </InputAdornment>
            }
            onChange={handleInputChange}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{
              mt: 1,
              mb: 2,
              fontSize: "1.2rem",
              textTransform: "none",
              backgroundColor: "rgba(53, 103, 118, 1)",
              ":hover": {
                backgroundColor: "rgba(53, 103, 118, 1)",
              },
            }}
            onClick={() => signInWithEmail()}
          >
            Log in with your email
          </Button>
          <Box>
            <Divider
              sx={{
                "&::before, &::after": {
                  borderColor: "white",
                },
                mb: 4,
                textAlign: "center",
              }}
            >
              <Typography>or</Typography>
            </Divider>
          </Box>
          {/* Call the corresponding functions per provider on click. */}
          <GoogleLoginButton align="center" onClick={signInWithGoogle} />
          <GithubLoginButton align="center" onClick={signInWithGithub} />
          <Typography sx={{ mt: 2 }} color={"red"}>
            {errorMessage}
          </Typography>
          <Typography sx={{ mt: 2 }} color={"lightgreen"}>
            {informMessage}
          </Typography>
        </LoginForm>
      </LoginBox>
    </Container>
  );
};

export default AuthContainer;
